import React from 'react';
import './documentation.css';

const longText = `
Polityka prywatności

1.	Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez użytkowników w związku z korzystaniem przez nich z usług oferowanych za pośrednictwem serwisu internetowego apartamentydolinastrazyska.pl, zwanego dalej serwisem.
2.	Administratorem danych osobowych jest Jakub Koziara.
3.	Administrator przetwarza dane osobowe użytkowników serwisu przekazane przez nich dobrowolnie, w celu świadczenia drogą elektroniczną usług oraz w celu wykonania umów określonych w serwisie, w tym: w celu zawarcia i wykonania umowy najmu, dokonania płatności, korzystania z usługi formularza kontaktowego, rozwiązywania problemów technicznych związanych z ww. usługami, kontaktowania się z wynajmującym w związku z zawarciem i wykonywaniem umowy (art. 6 ust. 1 lit b rozporządzenie Parlamentu Europejskiego i Rady Unii Europejskiej z 2016/679 z dnia 27 kwietnia 2016 r. zwanym dalej ,,RODO”), obsługi reklamacji (art. 6 ust. 1 lit. b i c RODO), prowadzenia działań marketingowych dotyczących działań wynajmującego, jak i świadczonych przez niego usług, dochodzenia roszczeń związanych z zawartą umową (art. 6 ust. 1 lit. f RODO).
Przetwarzane dane osobowe będą obejmowały swoim zakresem takie dane jak: imię, nazwisko, data urodzenia, adres zamieszkania, numer telefonu, adres email. Podanie danych jest niezbędne do wykonania przez Administratora usług i umów opisanych w serwisie. Niepodanie danych osobowych, bądź niewyrażenie zgody na ich przetwarzanie będzie skutkowało brakiem możliwości zawarcia i wykonania umowy.
4.	Użytkownik ma prawo nieograniczonego dostępu do swoich danych osobowych, podobnie jak w każdym czasie może żądać ich zmiany oraz usunięcia, a nadto może cofnąć zgodę na ich przetwarzanie.
5.	Administrator realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w następujący sposób:
5.1	poprzez dobrowolnie wprowadzenie informacji w formularzach,
5.2	poprzez gromadzenie plików “cookies”.
6.	Podstawą prawną przetwarzania danych osobowych jest rozporządzenie Parlamentu Europejskiego i Rady Unii Europejskiej z 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/W.
7.	Każdy użytkownik serwisu może wnieść skargę do Urzędu Ochrony Danych Osobowych. 


Informacja o „cookies”

1.	Podmiotem zamieszczającym informacje w formie plików cookies (tzw. ciasteczek) i innych podobnych technologii w urządzeniu końcowym użytkownika (np. komputerze, laptopie, smartfonie, Smart TV) oraz uzyskującym do nich dostęp jest Administrator oraz podmioty współpracujące m.in. partnerzy świadczący usługi analityczne, reklamodawcy, twórcy aplikacji, sieciowe agencje reklamowe (dalej będziemy używać skrótu „Podmioty zamieszczające”).
2.	Pliki cookies stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym użytkownika serwisu. Cookies zazwyczaj zawierają nazwę domeny serwisu internetowego, z którego pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.  Pliki cookies nie służą identyfikacji użytkownika i na ich podstawie nie jest ustalana tożsamość użytkownika.
3.	Strona internetowa Administrator może umieścić plik cookie w przeglądarce, jeśli przeglądarka to umożliwia. Co ważne, przeglądarka zezwala stronie internetowej na dostęp jedynie do plików cookies umieszczonych przez tę stronę, a nie do plików umieszczonych przez inne strony internetowe.
4.	Korzystamy z plików cookies i innych podobnych technologii w celach: świadczenia usług; dostosowywania zawartości serwisów i aplikacji do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych; np. pliki cookies pozwalają w szczególności rozpoznać urządzenie użytkownika i odpowiednio wyświetlić stronę internetową dostosowaną do jego indywidualnych potrzeb; tworzenia statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy korzystają ze serwisów i aplikacji, co umożliwia ulepszanie ich struktury i zawartości; utrzymania sesji użytkownika (po zalogowaniu), dzięki czemu użytkownik nie musi na każdej podstronie internetowej danego serwisu i aplikacji ponownie wpisywać loginu i hasła; prezentacji reklam, m.in. w sposób uwzględniający zainteresowania Użytkownika czy jego miejsce zamieszkania (indywidualizowanie przekazu reklamowego) i z gwarancją wyłączenia możliwości wielokrotnego prezentowania użytkownikowi tej samej reklamy; 
5.	Nie używamy tej technologii w celu "śledzenia" lub identyfikowania użytkowników, a wyłącznie dla wygody i w celu świadczenia usług na jak najwyższym poziomie. 
`

const PolitykaPrywatnosci = () => {
  return (
    <div className='document-container'>
      <pre>{longText}</pre>
    </div>
  );
};

export default PolitykaPrywatnosci;
