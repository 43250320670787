import React from 'react';
import './documentation.css';

const longText = `
Wprowadzenie

Niniejszy regulamin ma na celu określenie zasad rezerwacji i wynajmu apartamentów Apartamenty Dolina Strążyska oraz warunki pobytu.

Dokonanie rezerwacji pobytu w wybranym apartamencie oznacza zaakceptowanie postanowień zawartych w niniejszym regulaminie. Prezentowany regulamin stanowi integralną cześć umowy wynajmu (rezerwacji).

Treść regulaminu dostępna jest na stronie www.apartamentydolinastrazyska.pl.

1.	Niniejszy Regulamin jest regulaminem, o którym mowa w art. 8 ust. 1 pkt. 1 ustawy z dnia 18.07.2002 r. o świadczeniu usług drogą elektroniczną.

§1. Definicje

1.	Właściciel - podmiot posiadający prawo własności lub ustanowione na jego rzecz prawo zależne do dysponowania lokalem będącym przedmiotem najmu.
2.	Apartament - lokal mieszkalny znajdujący się w budynku położonym w Zakopanem przy ulicy Strążyska 4A, szczegółowo opisany na stronie internetowej www.apartamentydolinastrazyska.pl, który Najemca wynajmuje na pobyt krótkoterminowy w celach wypoczynkowych i turystycznych.
3.	Gość - osoba fizyczna lub podmiot gospodarczy, biorący w odpłatne, określone w czasie użytkowanie, należący do Właściciela apartament, na podstawie dokonanej rezerwacji apartamentu. 
4.	System Rezerwacji - system dostarczony przez zewnętrzny podmiot, służący do dokonywania rezerwacji pobytów oraz płatności poprzez stronę internetową apartamentydolinastrazyska.pl

§2. Postanowienia wprowadzające

1.	Wynajem apartamentów prowadzony jest w trybie dobowym.
2.	O ile Strony nie uzgodniły inaczej, doba pobytu trwa od godziny 15.00 w dniu przybycia do godziny 11.00 w dniu gdy pobyt zostaje zakończony (lub wcześniej, do chwili zdania karty/kluczy do Apartamentu).
3.	Gościem może być osoba pełnoletnia. Zawarta umowa obejmuje jedynie wynajem apartamentu (bez wyżywienia i dojazdu).
4.	Gość bez pisemnej zgody Właściciela nie może przekazywać Apartamentu do korzystania osobom trzecim.

§3. Rezerwacja oraz płatność za wynajem apartamentu
1.	Sposoby dokonywania rezerwacji: 
1)	telefonicznie pod numerem telefonu: +48 607 601 471
2)	poprzez adres mailowy apartamentydolinastrazyska@gmail.com
3)	on-line na stronie apartamentydolinastrazyska.pl
2.	W celu dokonania rezerwacji należy podać: 
1)	datę planowanego przyjazdu oraz liczbę nocy (dób)
2)	planowana godzinę przyjazdu;
3)	liczbę osób w apartamencie;
4)	imię i nazwisko;
5)	adres zamieszkania;
6)	numer telefonu;
7)	adres e-mail.
3.	Po dokonaniu zgłoszenia na wskazany adres mailowy Gościa zostanie wysłane "Potwierdzenie wstępnej rezerwacji" wybranego apartamentu wraz z kosztem całego pobytu oraz wysokością zadatku który należy wpłacić na wskazany numer kont w przeciągu 1 dnia roboczego od otrzymania potwierdzenia wstępnej rezerwacji. Po zaksięgowaniu zadatku w wysokości 30% rezerwacja zmienia status na "Rezerwację Gwarantowaną", w związku z czym Goście otrzymują na swój adres mailowy "Potwierdzenie rezerwacji". Brak wpłaty zadatku w wyznaczonym terminie oznacza odstąpienie od umowy oraz automatyczne anulowanie wstępnej rezerwacji.
4.	Pozostałą do uregulowania należność w wysokości 70% wartości pobytu Goście zobowiązani są wpłacić najpóźniej w dniu meldunku.
5.	W przypadku anulowania lub odstąpienia z rezerwacji wpłacony zadatek przepada. Możliwość zmiany terminu rezerwacji możliwe jest wyłącznie za zgodą Właściciela. W przypadku skrócenia pobytu klient zobowiązany jest do pokrycia 100% kosztów noclegów. Ewentualne zmiany powinny być zgłoszone telefonicznie lub mailowo do Apartamenty Dolina Strążyska.
6.	Właściciel oświadcza, że udostępnienie przedmiotowego Apartamentu może nastąpić tylko i wyłącznie po uprzednim pobraniu całej należnej opłaty za pobyt.

§4. Odpowiedzialność za apartament - mienie powierzone
1.	Goście odpowiadają za wszelkie wyrządzone w wyposażeniu Apartamentu oraz jego instalacjach szkody, nie wynikające z normalnego użytkowania.
2.	W przypadku stwierdzenia spowodowania pogorszenia stanu Apartamentu przez Gości, Właściciel ma prawo obciążyć Gości kwotą proporcjonalną do wartości wyrządzonych szkód lub w pełnym zakresie.
3.	W przypadku wyrządzenia szkody w Apartamencie Goście zobowiązani są do zapłaty odszkodowania w pełni pokrywającego wartość poniesionych strat, najpóźniej w dniu wyjazdu gotówką na ręce Właściciela.
4.	Gość Apartamentu ponosi pełną odpowiedzialność za powierzone mu klucze/karty do Apartamentu oraz piloty do bram wjazdowych. Zagubienie karty/klucza lub pilota obciążane jest dodatkową opłatą w wysokości 350 PLN.
5.	Gość Apartamentu jest zobowiązany dokonać kontroli pełnego zamknięcia apartamentu w chwili jego opuszczania.
6.	Właściciel Apartamentów nie bierze odpowiedzialności za rzeczy pozostawione w Apartamencie oraz ich ewentualne zaginięcie lub uszkodzenie. 

§5. Wykorzystanie miejsc parkingowych
1.	Goście Apartamentu zobowiązani są do parkowania posiadanych pojazdów w miejscach wyznaczonych.
2.	Zajęcie miejsca parkingowego w sposób uniemożliwiający podmiotowi trzeciemu wyjechanie z parkingu pociąga za sobą ryzyko odholowania pojazdu Gościa na jego koszt.

§6. Zasady pobytu w Apartamencie
1.	Obowiązują ogólne zasady porządku i spokoju publicznego w godzinach 22:00-6:00. Przy przekroczeniu tych zasad Właściciel jest upoważniony do natychmiastowego zerwania umowy oraz obciążenia Gości karą pieniężną w wysokości 500 zł.
2.	W przypadku interwencji Właściciela bądź służb porządkowych osoba zakłócająca spokój publiczny zostanie ukarana grzywną porządkową w wysokości 500 zł.
3.	Goście ponoszą pełną odpowiedzialność za wszelkie szkody w Apartamencie lub częściach wspólnych budynku, które powstały z ich winy lub winy osób ich odwiedzających. W przypadku takich szkód, Goście są zobowiązani do zapłaty odszkodowania, które w pełni pokrywa wartość poniesionych strat.
4.	Gość w dniu zameldowania powinien zgłosić wszelkie braki lub usterki znajdujące się w Apartamencie.
5.	Niezgłoszenie uwag i zastrzeżeń (w formie pisemnej: sms, email etc.) co do stanu technicznego w dniu zameldowania do apartamentu równoznaczne jest z tym, że apartament, jak i zawarte w nim sprzęty, został oddany do użytku w stanie nie budzącym uwag i zastrzeżeń gościa.
6.	Na terenie obiektu obowiązuje bezwzględny zakaz palenia papierosów, wyrobów tytoniowych, papierosów elektronicznych oraz spożywania środków odurzających, w przypadku złamania tego zakazu, Właściciel ma prawo pobrać od Gościa kwotę w wysokości 1000 zł.
7.	Przebywanie na terenie obiektu zwierząt jest niedozwolone. Złamanie tego zakazu skutkuje karą pieniężną w wysokości 1000 zł.
8.	Większe niż normalne zużycie ręczników skutkujące ich zniszczeniem, obciążane jest dodatkową opłatą w wysokości 50 zł za sztukę.
9.	Właściciel pobiera odpowiednią opłatę klimatyczną (2,00 zł za osobę za dobę).
10.	Gość ponosi pełną odpowiedzialność za bezpieczeństwo swoje, innych osób przebywających w Apartamencie oraz za wszelkie powstałe szkody wynikające z niewłaściwego użytkowania Apartamentu oraz części wspólnej.
11.	Dzieci poniżej 13 lat, przez cały czas pobytu muszą znajdować się pod stałą opieką i nadzorem osób dorosłych. Za zachowania dzieci, w tym poczynione uszkodzenia i szkody odpowiadają opiekunowie prawni dzieci.
12.	Zabrania się poruszania po obiekcie w obuwiu narciarskim i wnoszenia sprzętu narciarskiego lub rowerów do apartamentów - do tego celu służy pomieszczenie zwane Narciarnią na poziomie -1. 
13.	Goście zobowiązani są korzystać z Apartamentów i części wspólnych budynku zgodnie z ich przeznaczeniem oraz utrzymywać je w stanie należytym i odpowiadającym zastanym standardom. Zakazane jest dokonywanie jakichkolwiek przeróbek, ulepszeń i modyfikacji Apartamentów oraz ich wyposażenia i umeblowania.

§7. Zakończenie pobytu
1.	W dniu wyjazdu Gość jest zobowiązany udostępnić wynajmowany Apartament Właścicielowi w celu skontrolowania stanu powierzonego Apartamentu.
2.	Wyjazd z Apartamentu po godzinie 11.00, bez wcześniejszego ustalenia z Właścicielem, traktowany będzie jako przedłużenie pobytu o kolejną dobę i liczony według obowiązującego cennika.
3.	Przedłużenie pobytu należy zgłosić telefonicznie najpóźniej do godziny 17.00 w dniu poprzedzającym dzień upływu terminu objętego dokonaną rezerwacją. Przedłużenie pobytu nastąpi, jeśli będą dyspozycyjne wolnymi apartamentami.
4.	Gość jest zobowiązany zgłosić Właścicielowi wszelkie, powstałe szkody w wyposażeniu apartamentu.
5.	Przed opuszczeniem apartamentu Gość jest zobowiązany zwrócić otrzymane w chwili zakwaterowania klucze/karty oraz piloty do bram wjazdowych.
6.	Przed opuszczeniem Apartamentu Gość jest zobowiązany wyrzucić śmieci powstałe podczas swojego pobytu.

§8. Siła wyższa
1.	Poprzez siłę wyższą przyjmuje się wszelkie, niezależne od Właściciela, niemożliwe do przewidzenia okoliczności faktyczne, uniemożliwiające wynajem Gościom określonego w rezerwacji Apartamentu, które to okoliczności są niemożliwe do natychmiastowego usunięcia standardowymi środkami.
2.	Siłą wyższą są również wszelkie niezależne od podmiotów wskazanych w pkt. 1 okoliczności stwarzające bezpośrednie lub potencjalne zagrożenie wystąpienia uszczerbku na zdrowiu, życiu lub majątku Gości.
3.	W sytuacji określonej punktem poprzedzającym, Właściciel zastrzega sobie prawo zaproponowania Gościom obiektu zastępczego o porównywalnym lub zbliżonym standardzie do obiektu pierwotnie zarezerwowanego.
4.	Właściciel ma również prawo, w przypadku wystąpienia siły wyższej anulować umowę ze zwrotem uzyskanych świadczeń, z uwzględnieniem kosztów usług już zrealizowanych na rzecz Gości.

§9. Przedterminowe opuszczenie apartamentu
1.	Gość, skracający pobyt w apartamencie z przyczyn od niego zależnych (w tym też z powodów osobistych lub wypadków natury losowej), obowiązany jest niezwłocznie zawiadomić Apartamenty Dolina Strążyska o powstałym fakcie wraz z określeniem terminu opuszczenia Apartamentu.
2.	Skrócenie pobytu Gości w Apartamencie z przyczyn niezależnych od podmiotów wskazanych w §1 pkt. 1-2 lub opuszczenie Apartamentu przez Gości bez wykonania obowiązku określonego w pkt. 1 nie powoduje powstania roszczenia o zwrot opłaty czynszowej za niewykorzystane dni pobytu.
3.	W przypadku nieopłacenia przez Gości opłaty za pobyt w Apartamencie, w sytuacji określonej w punkcie poprzedzającym, Właściciel ma prawo dochodzić części należnej mu opłaty na drodze windykacyjnej i procesowej.
4.	W sytuacji określonej w pkt. 3 Właściciel zastrzega sobie prawo cesji powstałej wierzytelności na wybrany przez niego podmiot.

§10. Reklamacje
1.	W razie zaistnienia nieprawidłowości Gość ma prawo do złożenia reklamacji w terminie 14 dni od ich wystąpienia. Reklamacje należy przesyłać pocztą elektroniczną na adres e-mail: apartamentydolinastrazyska@gmail.com
2.	Rozpatrzenie reklamacji następuje w terminie 30 dni roboczych od jej otrzymania. O jej rozpatrzeniu Gość zostanie niezwłocznie powiadomiony drogą elektroniczną na odpowiedni adres wskazany w treści reklamacji.

§11. Postanowienia końcowe
1.	Podmiot określony w §1 pkt. 1-2 nie ponosi odpowiedzialności za mienie Gości pozostawione na terenie apartamentu, miejscu parkingowym lub miejscu przyległym do Apartamentu rozumianym jako cześć wspólna nieruchomości, w której Apartament się znajduje.
2.	Podmiot określony w §1 pkt. 1-2 nie ponosi odpowiedzialności za sposób wykorzystywania lokali niebędących własnością lub niebędących zarządzanymi przez wyżej wskazanych, sąsiadujących lub mogących wywierać wpływ na lokal będący przedmiotem najmu, jak również nie ponosi odpowiedzialności za wykorzystywanie części wspólnych budynku przez podmioty trzecie.
3.	Gdyby na skutek zmiany obowiązującego prawa, jakiekolwiek postanowienia Regulaminu utraciły ważność, stały się nielegalne lub niewykonalne, pozostałe postanowienia nie będą naruszane w zakresie dozwolonym przez prawo.
4.	Klient oświadcza, że został poinformowany o treści art. 38 pkt. 12 ustawy z dnia 30 maja 2014 r. o prawach konsumenta, zgodnie z którym w przypadku umów o świadczenie usług w zakresie zakwaterowania, innych niż do celów mieszkalnych, konsumentowi nie przysługuje przewidziane w art. 27 tej ustawy prawo do odstąpienia od umowa zawartej na odległość.
5.	Regulamin wchodzi w życie z dniem opublikowania na stronie www.apartamentydolinastrazyska.pl
________________________________________
KLAUZULA INFORMACYJNA
Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016) informuję, iż:
1.	Administratorem Pani/Pana danych osobowych jest Jakub Koziara.
2.	Pani/Pana dane osobowe przetwarzane będą w celu korzystania z usług wynajmu apartamentów - na podstawie Art. 6 ust. 1 lit. b ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r.
3.	Odbiorcami Pani/Pana danych osobowych będą wyłącznie podmioty uprawnione do uzyskania danych osobowych na podstawie przepisów prawa oraz podmioty uczestniczące w realizacji usług.
4.	Podstawą przetwarzania danych osobowych jest:
4.1	Art. 6 ust. 1 lit. b RODO – niezbędność ich przetwarzania w celu realizacji i wykonania umowy;
4.2	Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes administratora.
5.	Pani/Pana dane osobowe przechowywane będą przez okres 10 lat.
6.	Posiada Pani/Pan prawo do żądania od administratora dostępu do danych osobowych, prawo do ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz prawo do przenoszenia danych.
7.	Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego.
8.	Podanie danych osobowych jest dobrowolne, jednakże odmowa podania danych może skutkować odmową realizacji usługi/umowy.
`;

const Documentation = () => {
  return (
    <div className='document-container'>
      <pre>{longText}</pre>
    </div>
  );
};

export default Documentation;
